import React, {FC, memo, useEffect, useState} from 'react';
import {useQuery} from '@apollo/client';
import jwt_decode from "jwt-decode";
import useSetUser from '../hooks/useSetUser';
import {getRawCookie, getUserToken, setUserToken} from '../helpers';
import {GET_ACCESS_TOKEN} from '../graphql/queries';

const Authentication: FC<{children: JSX.Element}> = ({children}) => {
  const [updatedToken, setUpdatedToken] = useState();
  const [tokenExpDate, setTokenExpDate] = useState(900000);

  const {data: getAccessTokenData} = useQuery(GET_ACCESS_TOKEN, {
    pollInterval: tokenExpDate,
    skip: !getRawCookie('ref_token') || !getRawCookie('device_key'),
    variables: {
      settings: {
        refresh_token: getRawCookie('ref_token'),
        device_key: getRawCookie('device_key'),
        product_type: 'hexoscope',
      },
    },
  });

  useEffect(() => {
    if (getAccessTokenData && getAccessTokenData.User && getAccessTokenData.User.getAccessToken) {
      if (getAccessTokenData.User.getAccessToken.token) {
        const jwtParsed = jwt_decode(getAccessTokenData.User.getAccessToken.token);
        //@ts-ignore
        const expDate = new Date((jwtParsed.exp || 0) * 1000) - new Date() - 55000;
        setTokenExpDate(expDate);
      }
      setUpdatedToken(getAccessTokenData.User.getAccessToken.token);
      setUserToken(getAccessTokenData.User.getAccessToken.token);
    }
  }, [getAccessTokenData]);

  const {getUser} = useSetUser();

  useEffect(() => {
    if ((getAccessTokenData?.User?.getAccessToken && updatedToken !== getUserToken()) || !getRawCookie('ref_token') || !getRawCookie('device_key')) {
      getUser();
    }
  }, [getAccessTokenData, updatedToken]);

  useEffect(() => {
    if (updatedToken === getUserToken()) {
      getUser();
    }
  }, [updatedToken, getUserToken()]);
  
  return <>{children}</>;
};

export default memo(Authentication);
