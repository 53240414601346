import React, {FC, memo, useState} from 'react';
import Authentication from '.';
import {UserContext} from '../contexts/user-context';
import {Children, HexoscopeUser, User} from '../types';

const Context: FC<Children> = ({children}) => {
  const [user, setUser] = useState<User | null>(null);
  const [hexoscopeUser, setHexoscopeUser] = useState<HexoscopeUser | null>(null);
  return (
    <UserContext.Provider value={{user, setUser, hexoscopeUser, setHexoscopeUser}}>
      <Authentication>{children}</Authentication>
    </UserContext.Provider>
  );
};

export default memo(Context);
