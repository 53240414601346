import React from 'react';
import {Helmet} from 'react-helmet';
import {Location} from '@reach/router';

const headerData = [
  {
    title: '',
    description: '',
    path: '/404',
  },
  {
    title: 'Online Content and Reputation Management Platform - Hexoscope',
    description:
      'New Way to Manage Your Business Online Content and Reputation',
    path: '/',
  },
  {
    title: 'Online Content and Reputation Management Platform - Hexoscope stacking pre-launch',
    description:
      '',
    path: '/stacking-pre-launch',
  },
  {
    title: 'Terms of Service | Hexoscope',
    description:
      'These Terms of Service contain an Arbitration Agreement that may impact your rights to use the courts to resolve disputes.',
    path: '/terms-of-use',
  },
  {
    title: 'Customer support contacts | Hexoscope',
    description: 'Customer support contacts - content change detection and alerting solution',
    path: '/contact',
  },
  {
    title: 'Privacy Policy | Hexoscope',
    description:
      'Your privacy is very important to us and we have created this privacy statement in order to demonstrate our commitment to you.',
    path: '/privacy-policy',
  },
  {
    title: 'Cookie Policy | Hexoscope',
    description: 'This Cookie Policy describes how and why HEXACT use cookies',
    path: '/cookie-policy',
  },
  {
    title: 'Team Access | Hexoscope',
    description: '',
    path: '/team-access',
  },
  {
    title: 'My Profile | Hexoscope',
    description: '',
    path: '/profile',
  },
  {
    title: 'My Plans | Hexoscope',
    description: '',
    path: '/profile/my-plans',
  },
  {
    title: 'Payment Method | Hexoscope',
    description: '',
    path: '/profile/payment-methods',
  },
  {
    title: 'Code Redeem | Hexoscope',
    description: '',
    path: '/profile/promo-codes',
  },
  {
    title: 'Security | Hexoscope',
    description: '',
    path: '/profile/security',
  },
];

export default () => {
  return (
    <Location>
      {({location}) => {
        const item = headerData.find(data => {
          if (location.pathname[location.pathname.length - 1] === '/' && location.pathname !== '/') {
            return data.path === location.pathname.substr(0, location.pathname.length - 1);
          } else if (location.pathname === '/') {
            return data.path === location.pathname;
          } else {
            return location.pathname === data.path || location.pathname === data.path + '/';
          }
        });
        return (
          item && (
            <Helmet key={item.title} title={item.title}>
              <html lang="en" />
              {!location.pathname.includes('/reset-password') &&
                !location.pathname.includes('/404') &&
                !location.pathname.includes('/team-access') && (
                  <script type="text/javascript">
                    {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','GTM-KMPKSGN');`}
                  </script>
                )}
              <script type="application/ld+json">
                {`{"@context": "https://schema.org","@type": "Organization","name": "Hexoscope","url": "https://hexoscope.com/","logo": "https://hexoscope.com/logo.png"}`}
              </script>
              <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
              <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
              <link rel="icon" type="image/png" sizes="192x192" href="/android-chrome-192x192.png" />
              <link rel="manifest" href="/site.webmanifest" />
              <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
              <link rel="canonical" href={`https://hexoscope.com${location.pathname}`} />
              <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#0038ff" />
              <meta name="msapplication-TileColor" content="#ffffff" />
              <meta name="msapplication-TileImage" content="/mstile-144x144.png" />
              <meta name="theme-color" content="#ffffff" />
              <meta name="msapplication-config" content="/browserconfig.xml" />

              <meta name="description" content={item.description} />
              <meta property="og:type" content="website" />
              <meta property="og:image" content={'https://hexoscope.com/Home-page-illustrations.jpg'} />
              <meta property="og:url" content={`https://hexoscope.com${location.pathname}`} />
              <meta property="og:title" content={item.title} />
              <meta property="og:description" content={item.description} />
              <meta property="og:locale" content={'en_US'} />
              <meta name="twitter:image" content={'https://hexoscope.com/Home-page-illustrations.jpg'} />
              <meta name="twitter:title" content={item.title} />
              <meta name="twitter:description" content={item.description} />
              <meta name="twitter:card" content={'summary_large_image'} />
              <meta name="twitter:site" content={'@hexactinc'} />
            </Helmet>
          )
        );
      }}
    </Location>
  );
};
